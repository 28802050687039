<template>
   <div>
	  <section id="projects-list">
		 <b-row>
			<b-col lg="3">
			   <b-card-actions action-collapse
							   title="Φίλτρα">
				  <!-- Filters -->
				  <b-form-group label="Από"
								label-for="start-date">
					 <flat-pickr
						 id="start-date"
						 v-model="form.from"
						 :config="{
					allowInput: true,
                  altInput: true,
                  altFormat: 'd-m-Y',
                  dateFormat: 'Y-m-d',
                }"
						 :value="null"
						 class="form-control"
					 >
					 </flat-pickr>
				  </b-form-group>
				  <b-form-group label="Έως"
								label-for="end-date">
					 <flat-pickr
						 id="end-date"
						 v-model="form.to"
						 :config="{
					allowInput: true,
                  altInput: true,
                  altFormat: 'd-m-Y',
                  dateFormat: 'Y-m-d',
                }"
						 :value="null"
						 class="form-control"
					 >
					 </flat-pickr>
				  </b-form-group>

				  <b-form-group label="Υπόλοιπο">
					 <b-form-radio-group
						 v-model="form.balance"
						 :options="BalanceOptions"
						 name="balance"
						 @input="$emit('selectedBalance', form.balance)"
					 />
				  </b-form-group>

				  <b-form-group label="Κατάσταση">
					 <v-select v-model="form.status"
							   :options="$store.getters.getCalendars"
							   :reduce="item=> item.label" />
				  </b-form-group>


				  <b-button block
							variant="outline-primary"
							@click="generateReport">
					 <span>{{ $t("generic.view-results") }}</span>
				  </b-button>
				  <b-button block
							variant="flat-warning"
							@click="reset">
					 Καθαρισμός
				  </b-button>
			   </b-card-actions>
			   <!-- END: Filters -->
			</b-col>

			<b-col lg="9">
			   <!--Πίνακας αποτελεσμάτων -->
			   <b-card no-body>
				  <b-row v-if="showPdf"
						 style="height: 100vh">


					 <vue-pdf-app :pdf="$store.getters.getProjectList"
								  class="w-100 h-100" />


				  </b-row>
			   </b-card>
			   <!--Πίνακας αποτελεσμάτων -->
			</b-col>
		 </b-row>
	  </section>
   </div>
</template>

<script>
import {
   BButton,
   BButtonGroup,
   BButtonToolbar,
   BCard,
   BCardText,
   BCol,
   BFormGroup,
   BFormRadio,
   BFormRadioGroup,
   BRow,
   BTable,
} from "bootstrap-vue";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import FormMixin from "@/Mixins/FormMixin";
import caldoSettings from "@/caldoSettings";

export default {
   components: {
	  BRow,
	  BCol,
	  BCard,
	  BCardText,
	  BFormGroup,
	  vSelect,
	  BButton,
	  BButtonToolbar,
	  BButtonGroup,
	  BCardActions,
	  BTable,
	  flatPickr,
	  BFormRadio,
	  BFormRadioGroup,
   },
   mixins: [FormMixin],
   data() {
	  return {
		 BalanceOptions: [
			{text: "Όλα"},
			{text: "Εξοφλημένα", value: "no_debt"},
			{text: "Με οφειλή", value: "with_debt"},
		 ],
		 showPdf: false,
		 form: {
			status: null,
			balance: null,
			from: null,
			to: null,
		 },
	  };
   },
   created() {
	  caldoSettings.removeBreadcrumb();
   },
   methods: {
	  reset(e) {
		 e.preventDefault();
		 this.resetField(this.form);
	  },
	  async generateReport() {
		 await this.$store.dispatch('fetchPdfProjectList', this.removeEmpty(this.form));
		 this.showPdf = true;
	  }
   }
};
</script>


<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
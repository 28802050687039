var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{attrs:{"id":"projects-list"}},[_c('b-row',[_c('b-col',{attrs:{"lg":"3"}},[_c('b-card-actions',{attrs:{"action-collapse":"","title":"Φίλτρα"}},[_c('b-form-group',{attrs:{"label":"Από","label-for":"start-date"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"start-date","config":{
					allowInput: true,
                  altInput: true,
                  altFormat: 'd-m-Y',
                  dateFormat: 'Y-m-d',
                },"value":null},model:{value:(_vm.form.from),callback:function ($$v) {_vm.$set(_vm.form, "from", $$v)},expression:"form.from"}})],1),_c('b-form-group',{attrs:{"label":"Έως","label-for":"end-date"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"end-date","config":{
					allowInput: true,
                  altInput: true,
                  altFormat: 'd-m-Y',
                  dateFormat: 'Y-m-d',
                },"value":null},model:{value:(_vm.form.to),callback:function ($$v) {_vm.$set(_vm.form, "to", $$v)},expression:"form.to"}})],1),_c('b-form-group',{attrs:{"label":"Υπόλοιπο"}},[_c('b-form-radio-group',{attrs:{"options":_vm.BalanceOptions,"name":"balance"},on:{"input":function($event){return _vm.$emit('selectedBalance', _vm.form.balance)}},model:{value:(_vm.form.balance),callback:function ($$v) {_vm.$set(_vm.form, "balance", $$v)},expression:"form.balance"}})],1),_c('b-form-group',{attrs:{"label":"Κατάσταση"}},[_c('v-select',{attrs:{"options":_vm.$store.getters.getCalendars,"reduce":function (item){ return item.label; }},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}})],1),_c('b-button',{attrs:{"block":"","variant":"outline-primary"},on:{"click":_vm.generateReport}},[_c('span',[_vm._v(_vm._s(_vm.$t("generic.view-results")))])]),_c('b-button',{attrs:{"block":"","variant":"flat-warning"},on:{"click":_vm.reset}},[_vm._v(" Καθαρισμός ")])],1)],1),_c('b-col',{attrs:{"lg":"9"}},[_c('b-card',{attrs:{"no-body":""}},[(_vm.showPdf)?_c('b-row',{staticStyle:{"height":"100vh"}},[_c('vue-pdf-app',{staticClass:"w-100 h-100",attrs:{"pdf":_vm.$store.getters.getProjectList}})],1):_vm._e()],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }